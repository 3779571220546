.mouse_animated {
  border-radius: 25%;
  animation-name: scroll4px;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;

  @keyframes scroll4px {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(4px);
      opacity: 0;
    }
  }

  @keyframes scroll12px {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(12px);
      opacity: 0;
    }
  }

  @media only screen and (min-width: 992px) {
    animation-name: scroll12px;

    .none {
      display: none;
    }
  }
}
